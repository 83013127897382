import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";


//components
import MeetingJoin from "./dyteJoin";
import MeetingStart from "./dyteInitiate";





function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/start" element={<MeetingStart/>}></Route>
          <Route path="/join" element={<MeetingJoin/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
