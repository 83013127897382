import { useEffect } from 'react';
import { useDyteClient } from '@dytesdk/react-web-core';
import { DyteMeeting } from '@dytesdk/react-ui-kit';

export default function MeetingStart



() {
  const [meeting, initMeeting] = useDyteClient();

  useEffect(() => {
    initMeeting({
      authToken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdJZCI6IjQxZTllYjgwLWE5ZWYtNDk0Yi1iOWEzLTAxNzRmODUyYjJhMyIsIm1lZXRpbmdJZCI6ImJiYjNkYTJiLWY1ZDgtNDhkOS1iYTE4LTdlZTA2MDQxYjUyYiIsInBhcnRpY2lwYW50SWQiOiJhYWFlZGI2Yy1mYTcyLTQ3OWUtODQ0ZC1hMzlmZDBiNjk4OGUiLCJwcmVzZXRJZCI6IjA0NDNjMmUwLTliYTUtNDdiOS1iZmVmLTk2MzYzYjEyYjk0YiIsImlhdCI6MTcwNTUyOTE0NiwiZXhwIjoxNzE0MTY5MTQ2fQ.MP7jf_-gmklynqom0Gupx0TfYKBxlEMs1xIOKVENwmQ4u91JR9N6yYGrKI0Q2NHft2wTgpa86ddPIwJCQwjeoqhdoXXcE7tVND-noYZrsvNkyuzkxCZcLGaYI66Rum6aNHG7xBCX6DPD7O6LwwqaqH8Di_ibpqxj-ejhBWt9nYJi1F4kxanV9m7LfzcFcCro6jqJNlgp0TYAXvOg0_KDrDySjbp3ZeyiPq-qjnxG-TcZPmVT9KuCU99hy_zVAmMJKPM7JaTOdp0up4QAKHCfWJJ9h__i32uvzlswVM0wQAw9lrLMFP0g8EQM_pPGew-X1G0GBqT_qNwDugW5AbxeGg',
      defaults: {
        audio: false,
        video: false,
      },
    });
  }, []);

  return <DyteMeeting meeting={meeting} />;
}