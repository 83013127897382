import { useEffect } from 'react';
import { useDyteClient } from '@dytesdk/react-web-core';
import { DyteMeeting } from '@dytesdk/react-ui-kit';

export default function MeetingJoin



() {
  const [meeting, initMeeting] = useDyteClient();

  useEffect(() => {
    initMeeting({
      authToken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdJZCI6IjQxZTllYjgwLWE5ZWYtNDk0Yi1iOWEzLTAxNzRmODUyYjJhMyIsIm1lZXRpbmdJZCI6ImJiYjNkYTJiLWY1ZDgtNDhkOS1iYTE4LTdlZTA2MDQxYjUyYiIsInBhcnRpY2lwYW50SWQiOiJhYWE1ZmJlNi0wOGZiLTQ5YzgtODM1OS03YjQ2YzI2YWY4NDIiLCJwcmVzZXRJZCI6ImY3ZTA4N2Y0LWJlZmEtNGNiZS04OTdmLTVhNWU3Zjk2MWU1YSIsImlhdCI6MTcwNTUyODE0NiwiZXhwIjoxNzE0MTY4MTQ2fQ.NA56LmsFvjYxWRyzGdA9kCfije0XFAH2p-JWodiBXW6SbtpwzDhMNv8JMT7rtSu2Zv33qGKHpwv-cJT31YRGf8iMRbYwmRjBbG8LiZf0sIi-Uf5Ix--LW_8S63wqlBHbGuHTng1XtaFJAC7T4HO6VWwCraw1L3YtXt72Yo59-ka-ftg6DYHqu66UlfAykqiLBEt9cAHut-b02q7vGQSTtqEkfwKWtteu3x5Ld9sD7OcCwa_ZqS4Vq3X6FCEgIBd7W_5y-utJ-cOfextFBi8NWT1F9dQua10ck7-G8rCQeX5SkA_TedR0AKXJB2LmplfYUM5fMbavylBfAi1Qa8oKxQ',
      defaults: {
        audio: false,
        video: false,
      },
    });
  }, []);

  return <DyteMeeting meeting={meeting} />;
}